<template>
    <b-container fluid>
        <div class="mb-2 row">
            <div class="col-12">
                <h4>{{ distributor.name}}</h4>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="card card-primary card-outline card-outline-tabs">
                    <div class="p-0 card-header border-bottom-0">
                        <ul class="nav nav-tabs" id="custom-tabs-four-tab" role="tablist">
                            <li class="nav-item">
                                <a href="#custom-tabs-four-general" class="nav-link active" id="custom-tabs-four-general-tab" data-toggle="pill" role="tab" aria-controls="custom-tabs-four-general" aria-selected="true">Allgemein</a>
                            </li>
                            <li class="nav-item">
                                <a href="#custom-tabs-four-articles" class="nav-link" id="custom-tabs-four-articles-tab" data-toggle="pill" role="tab" aria-controls="custom-tabs-four-articles" aria-selected="true">Artikel</a>
                            </li>
                            <li class="nav-item">
                                <a href="#custom-tabs-four-contacts" class="nav-link" id="custom-tabs-four-contacts-tab" data-toggle="pill" role="tab" aria-controls="custom-tabs-four-contacts" aria-selected="false">Kontakte</a>
                            </li>
                        </ul>
                    </div>
                    <div class="card-body">
                        <div class="tab-content" id="custom-tabs-four-tabContent">
                            <div class="tab-pane fade active show" id="custom-tabs-four-general" role="tabpanel" aria-labelledby="custom-tabs-four-general">
                                <form ref="form" @submit.stop.prevent="handleSubmit">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="row" >
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="name" class="control-label">Name:</label>
                                                        <input v-model="form.name" name="name" id="name" type="text" class="form-control form-cotrol-sm" :class="{'is-invalid': form.errors.has('name')}" disabled>
                                                        <has-error :form="form" field="name"></has-error>
                                                    </div>
                                                </div>

                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="number" class="control-label">Lieferantenummer:</label>
                                                        <input v-model="distributor.distributornumber" name="number" id="number" type="text" class="form-control form-cotrol-sm" :class="{'is-invalid': form.errors.has('distributornumber')}" disabled>
                                                        <has-error :form="form" field="distributornumber"></has-error>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <div class="custom-control custom-switch">
                                            <input type="checkbox" class="custom-control-input" id="customSwitch1" v-model="send_email" disabled>
                                            <label class="custom-control-label" for="customSwitch1">Bestellung per Mail</label>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="email" class="control-label">E-Mail:</label>
                                        <input v-model="form.email" type="email" class="form-control form-cotrol-sm" :class="{'is-invalid': form.errors.has('email')}" disabled>
                                        <has-error :form="form" field="email"></has-error>
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="form-group">
                                        <div class="custom-control custom-switch">
                                            <input type="checkbox" class="custom-control-input" id="customSwitch2" v-model="send_phone" disabled>
                                            <label class="custom-control-label" for="customSwitch2">Bestellung per Telefon</label>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="email" class="control-label">Telefon:</label>
                                        <input v-model="form.phone" type="text" class="form-control form-cotrol-sm" :class="{'is-invalid': form.errors.has('phone')}" :disabled="form.send_phone == 0">
                                        <has-error :form="form" field="phone"></has-error>
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="form-group">
                                        <div class="custom-control custom-switch">
                                            <input type="checkbox" class="custom-control-input" id="customSwitch3" v-model="send_web" disabled>
                                            <label class="custom-control-label" for="customSwitch3">Bestellung per Webshop</label>
                                        </div>
                                    </div>
                                    <div class="form-group" >
                                        <label for="email" class="control-label">URL:</label>
                                        <input v-model="form.web" type="email" class="form-control form-cotrol-sm" :class="{'is-invalid': form.errors.has('web')}" disabled>
                                        <has-error :form="form" field="web"></has-error>
                                    </div>
                                </div>
                            </div>
                                            

                                            

                                            <div class="form-group">
                                                <div class="row">
                                                    <div class="col-md-10">
                                                        <div class="form-group">
                                                            <label for="street" class="control-label">Straße:</label>
                                                            <input v-model="form.street" type="text" class="form-control form-cotrol-sm" :class="{'is-invalid': form.errors.has('street')}" disabled>
                                                            <has-error :form="form" field="street"></has-error>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-2">
                                                        <div class="form-group">
                                                            <label for="house_number" class="control-label">Nr.:</label>
                                                            <input v-model="form.house_number" type="text" class="form-control form-cotrol-sm" :class="{'is-invalid': form.errors.has('house_number')}" disabled>
                                                            <has-error :form="form" field="house_number"></has-error>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group">
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <label for="zipcode" class="control-label">PLZ:</label>
                                                        <input v-model="form.zipcode" type="text" class="form-control form-cotrol-sm" :class="{'is-invalid': form.errors.has('zipcode')}" disabled>
                                                        <has-error :form="form" field="zipcode"></has-error>
                                                    </div>

                                                    <div class="col-md-8">
                                                        <label for="city" class="control-label">Ort:</label>
                                                        <input v-model="form.city" type="text" class="form-control form-cotrol-sm" :class="{'is-invalid': form.errors.has('city')}" disabled>
                                                        <has-error :form="form" field="city"></has-error>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group">
                                                <label for="name" class="control-label">Allgemeine Infos (Intern)</label>
                                                <ckeditor :editor="editor" :config="editorConfig" v-model="form.description" disabled></ckeditor>
                                                <has-error :form="form" field="description"></has-error>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="tab-pane fade" id="custom-tabs-four-articles" role="tabpanel" aria-labelledby="custom-tabs-four-articles">
                                <div v-if="articles.length >= 1">
                                <b-row class="mb-3" >
                                        <b-col md="3">
                                            <b-form-input v-model="filter" type="search" id="filterInput" placeholder="Type to Search"></b-form-input>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col>
                                        <b-table striped hover outlined :fields="fields" :items="articles" :filter="filter" :per-page="perPage" :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">
                                            <template #cell(actions)="row">
                                                <router-link class="mr-1 btn btn-warning btn-sm" :to="{name: 'articles.details', params: {id: row.item.id}}" v-if="$auth.check('articles.show')"><i class="fas fa-edit"></i></router-link>
                                            </template>
                                        </b-table>
                                        <b-pagination v-model="currentPage" :total-rows="articleRows" :per-page="perPage" aria-controls="my-table"></b-pagination>
                                    </b-col>
                                </b-row>
                                </div>
                                <b-row v-if="articles.length == 0">
                                    <b-col>
                                        <h4>Dieser Lieferant hat keine Artikel</h4>
                                    </b-col>
                                </b-row>
                            </div>
                            <div class="tab-pane fade" id="custom-tabs-four-contacts" role="tabpanel" aria-labelledby="custom-tabs-four-contacts">
                                <b-row class="mb-3" >
                                        <b-col md="3">
                                            <b-form-input v-model="filter" type="search" id="filterInput" placeholder="Type to Search"></b-form-input>
                                        </b-col>
                                        <b-col md="3" offset-md="6" class="text-right">
                                            <b-button size="sm" @click="createModal" class="mr-1" variant="primary"><i class="fas fa-plus-circle" v-if="$auth.check('distributors.create')"></i> Neuer Kontakt</b-button>
                                        </b-col>
                                    </b-row>
                                    <b-row v-if="contacts.length >= 1">
                                        <b-col>
                                        <b-table striped hover outlined :fields="contactFields" :items="contacts" :filter="filter" :per-page="perPage" :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">
                                            <template #cell(actions)="row">
                                                <b-button size="sm" @click="editModal(row.item)" class="mr-1" variant="warning" v-if="$auth.check('distributors.edit')"><i class="fas fa-edit" ></i></b-button>
                                                <b-button size="sm" @click="deleteContact(row.item.id)" variant="danger" v-if="$auth.check('distributors.edit')"><i class="fas fa-trash" ></i></b-button>
                                            </template>
                                        </b-table>
                                        <b-pagination v-model="currentPage" :total-rows="contactRows" :per-page="perPage" aria-controls="my-table"></b-pagination>
                                    </b-col>
                                </b-row>
                             
                                <b-row v-if="contacts.length == 0">
                                    <b-col>
                                        <h4>Dieser Lieferant hat keine Kontakte</h4>
                                    </b-col>
                                </b-row>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="contactModal" v-bind:title="modalTitle" ok-only ok-variant="danger" ok-title="Speichern" size="xl" @ok="handleOk($event, contactForm.id)">
                <form ref="contactForm" @submit.stop.prevent="handleSubmit">
                    <div class="row">
                        <div class="col-md-12">

                            <div class="form-group">
                                <label for="name" class="control-label">Name:</label>
                                <input v-model="contactForm.name" type="text" class="form-control form-cotrol-sm" :class="{'is-invalid': contactForm.errors.has('name')}" disabled>
                                <has-error :form="contactForm" field="name"></has-error>
                            </div>

                            <div class="form-group">
                                <label for="department" class="control-label">Abteilung:</label>
                                <input v-model="contactForm.department" type="text" class="form-control form-cotrol-sm" :class="{'is-invalid': contactForm.errors.has('department')}" disabled>
                                <has-error :form="contactForm" field="department"></has-error>
                            </div>

                            <div class="form-group">
                                <label for="email" class="control-label">E-Mail:</label>
                                <input v-model="contactForm.email" type="email" class="form-control form-cotrol-sm" :class="{'is-invalid': contactForm.errors.has('email')}" disabled>
                                <has-error :form="contactForm" field="email"></has-error>
                            </div>

                            <div class="form-group">
                                <label for="phone" class="control-label">Telefon:</label>
                                <input v-model="contactForm.phone" type="text" class="form-control form-cotrol-sm" :class="{'is-invalid': contactForm.errors.has('phone')}" disabled>
                                <has-error :form="contactForm" field="phone"></has-error>
                            </div>

                            
                        </div>
                    </div>
                </form>
            </b-modal>
    </b-container>
</template>

<script>
import ClassicEditor from './../../assets/packages/ckeditor5/src/ckeditor';


export default {
    name: "Distributor-Details",
    title: "Lieferantendetails",

    data() {
        return {
            orderForm: new window.Form({
                id: "",
                orderinfo: "",
            }),
            send_email: 0,
            send_phone: 0,
            send_web: 0,
            form: new window.UploadForm({
                id: "",
                name: "",
                description: "",
                street: "",
                house_number: "",
                zipcode: "",
                city: "",
                number: "",
                send_email: "",
                send_phone: "",
                send_web: "",
                email: "",
                phone: "",
                web:"",
                _method: "PUT",
            }),
            contactForm: new window.Form({
                id: "",
                name: "",
                department: '',
                phone: '',
                email: '',
            }),
            editor: ClassicEditor,
            editorConfig: {
                simpleUpload: {
                    uploadUrl: process.env.VUE_APP_API_URL + 'images/upload',
                    withCredentials: true,
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': 'Bearer '+ this.$auth.token()
                    }
                }
            },
            modalTitle: "Neuen Kontakt erstellen",
            articles: [],
            contacts: [],
            distributor: {},
            editMode: false,
            editModeContact: false,
            sortBy: "id",
            sortDesc: false,
            perPage: 20,
            currentPage: 1,
            filter: "",
            fields: [
                {key: "ordernumber", label: "Artikelnummer", sortable: true},
                // {key: "active", label: "Aktiv", sortable: true, formatter: 'isActive'},
                {key: "name", label: "Name", sortable: true},
                {key: "tax.description", label: "Steuersatz", sortable: true},
                {key: "actions", label: "Actions", tdClass: 'hls_action_column'},
            ],
            contactFields: [
                {key: "id", sortable: true},
                {key: "name", label: "Name", sortable: true},
                {key: "department", label: "Abteilung", sortable: true},
                {key: "email", label: "E-Mail", sortable: true },
                {key: "phone", label: "Telefon", sortable: true},
                {key: "actions", label: "Actions", tdClass: 'hls_action_column'},
            ],
        }
    }, 

    watch:{
        send_email: function(){
            //this.form.email = "";
            if(this.send_email == true)
            {
                this.form.send_email = 1;
            }
            else
            {
                this.form.send_email = 0;
            }
        },

        send_phone: function(){
            //this.form.phone = "";
            if(this.send_phone == true)
            {
                this.form.send_phone = 1;
            }
            else
            {
                this.form.send_phone = 0;
            }
        },

        send_web: function(){
            //this.form.web = "";
            if(this.send_web == true)
            {
                this.form.send_web = 1;
            }
            else
            {
                this.form.send_web = 0;
            }
        }
    },

    methods:{
        editOrderinfo(){
            this.$Progress.start();
            this.orderForm
                .post("/distributors/saveOrderinfo/" + this.distributor.id )
                .then(() => {
                    this.loadDistributor();
                    this.$swal({
                        icon: "success",
                        title: "Bestellinformationen wurden editiert",
                    });
                    this.$Progress.finish();
                    
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        handleOk(bvModalEvt, id) {
            bvModalEvt.preventDefault()
            this.handleSubmit(id)
        },

        handleSubmit(id) {
            if(this.editModeContact == true)
            {
                //Edit User
                this.editContact(id);
            }
            else
            {
                //Create User
                this.createContact();
            }
        },

        createModal() {
            this.contactForm.clear();
            this.contactForm.reset();
            this.editModeContact = false;
            this.$bvModal.show("contactModal");
        },

        editModal(Contact) {
            this.editModeContact = true;
            this.modalTitle = "Kontakt bearbeiten";
            this.contactForm.reset();
            this.contactForm.fill(Contact);
            this.$bvModal.show("contactModal");

        },

        isActive(value){
            if(value == 1)
            {
                return '<i class="fas fa-check"></i>';
            }
            else
            {
                return '<i class="fas fa-times"></i>';
            }
        },

        editModeOn() {
            this.editMode = true;
        },

        editModeOff() {
            this.form.reset();
            this.form.fill(this.distributor);
            this.form._method ="PUT";
            this.editMode = false;
        },

        createContact() {
            this.$Progress.start();
            this.contactForm
                .post("/distributors/" + this.distributor.id + '/contacts')
                .then(() => {
                    this.loadDistributor();
                    this.$bvModal.hide("contactModal");
                    this.$swal({
                        icon: "success",
                        title: "Kontakt wurde hinzugefügt",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.$Progress.finish();
                    
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufennnnn",
                    });
                    this.$Progress.fail();
                });
        },

        editContact(id) {
            this.$Progress.start();
            this.contactForm
                .put("/distributors/" + this.distributor.id + '/contacts/' + id)
                .then(() => {
                    this.loadDistributor();
                    this.$bvModal.hide("contactModal");
                    this.$swal({
                        icon: "success",
                        title: "Der Kontakt wurde editiert",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.$Progress.finish();
                    
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufennnnn",
                    });
                    this.$Progress.fail();
                });
        },

        deleteContact(id){
            this.$swal({
                title: "Möchtest du den Kontakt wirklich löschen?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: `Ja, löschen!!!`,
                denyButtonText: `Abbrechen`,
            })
            .then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    //Send request to the Server
                    this.form
                        .delete("/distributors/" + this.distributor.id + '/contacts/' + id)
                        .then(() => {
                            this.$swal({
                                icon: "success",
                                title: "Kontakt wurde gelöscht!",
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 2000,
                                timerProgressBar: true,
                                });
                            this.loadDistributor();
                        })
                        .catch(() => {
                            this.$swal({
                                icon: "error",
                                title: "Oops...",
                                text: "Da ist etwas schief gelaufen",
                            });
                        });
                }
            });
        },

        editDistributor() {
            this.$Progress.start();
            this.form
                .post("/distributors/"+this.distributor.id)
                .then(() => {
                    this.loadDistributor();
                    this.editModeOff();
                    this.$swal({
                        icon: "success",
                        title: "Lieferant wurde editiert",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.$Progress.finish();
                    
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufennnnn",
                    });
                    this.$Progress.fail();
                });
        },

        loadDistributor() {
            this.$Progress.start();
            this.axios
                .get("/distributors/" + this.$route.params.id)
                .then((response) => {
                    this.distributor = response.data.data;
                    this.articles = this.distributor.articles;
                    this.contacts = this.distributor.contacts;
                    if(this.distributor.description == null)
                    {
                        this.distributor.description = "";
                    }
                    this.send_email = this.distributor.send_email;
                    this.send_phone = this.distributor.send_phone;
                    this.send_web = this.distributor.send_web;
                    this.form.fill(this.distributor);
                    this.orderForm.fill(this.distributor);
                    this.form._method = "PUT";
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        }
    },

    created() {
        this.loadDistributor();
        
    },

    computed: {
        tableData() {
                return this.articles || [];
            },

        articleRows() {
            return this.articles.length;
        },

        contactRows() {
            return this.contacts.length;
        }
    },
}
</script>

<style>

</style>